.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    max-width: 80%;
    max-height: 80vh;
    overflow: hidden;
}

.modalContent {
    max-height: calc(80vh - 40px);
    overflow-y: auto;
    padding-right: 20px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .modal {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .modalContent {
        max-height: calc(100% - 20px);
        padding-right: 10px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.recipeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recipeCard h2 {
    margin-bottom: 10px;
}

.recipeCard img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.recipeCard ul {
    list-style: none;
    padding: 0;
}

.recipeCard li {
    margin-bottom: 5px;
}

.ingredientRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ingredientInput {
    width: calc(100% - 40px);
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.addButton {
    padding: 8px 16px;
    margin-bottom: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addButton:hover {
    background-color: #0056b3;
}

.deleteButton {
    padding: 8px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: #c82333;
}

.editButton {
    padding: 8px 16px;
    margin-bottom: 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.editButton:hover {
    background-color: #218838;
}

.textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.emailInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}

.submitButton:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
}

.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.confirmation h2 {
    margin-bottom: 10px;
}

.confirmation p {
    margin-bottom: 20px;
}

.closeButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: #0056b3;
}
