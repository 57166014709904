body {
    background-color: #f0f0f0; /* Light grey background color */
}

.home .content {
    background-color: #ffffff; /* White background color for the content area */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.default-view {
    padding: 20px;
}

.featured {
    margin-bottom: 40px;
}

.featured h2 {
    margin-bottom: 10px;
}

.featured-image {
    width: 100%; /* Make the image fill its container */
    max-width: 100%; /* Allow full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    padding: 0; /* Remove any existing padding */
    margin: 0 auto; /* Center the image if it's wider than its container */
    display: block; /* Remove any inline spacing */
}

.recent-recipes {
    margin-bottom: 40px;
}

.recipe-image {
    width: 61%; /* 61% is 100% - 39% */
    height: auto;
    padding: none;
    margin: 4px;
}

.recent-recipes h2 {
    margin-bottom: 10px;
}

.recent-recipes-list {
    display: flex;
    gap: 20px;
}

.recipe-item {
    flex: 1;
    text-align: center;
}

.recipe-image {
    width: 100%; /* Ensure it takes full width of its container */
    height: auto;
    max-width: 100%; /* Set a base width */
    margin-bottom: 10px;
}

.featured-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.featured-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.featured-title {
    margin-bottom: 10px;
    width: 100%;
}

.featured-image-wrapper {
    width: 125%;
    height: 375px;
    position: relative;
    overflow: hidden;
    margin-left: -12.5%;
}

.featured-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featured-recipe-title {
    margin-top: 10px;
    font-weight: bold;
    width: 100%;
}

.featured-description {
    margin-top: 5px;
    width: 100%;
}

.Newsletter {
    flex: 0 0 52.5%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    align-items: center;
    box-sizing: border-box;
}

.Newsletter .input-group {
    width: 80%;
    padding: 10px;
    align-items: center;
}

.Newsletter input,
.Newsletter button {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .featured-content {
        flex-direction: column;
    }

    .featured-wrapper,
    .Newsletter {
        width: 100%;
        flex: none;
    }

    .featured-image-wrapper {
        width: 100%;
        height: auto;
        margin-left: 0;
    }

    .featured-image {
        height: auto;
    }

    .featured-title,
    .featured-recipe-title,
    .featured-description {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .Newsletter {
        margin-top: 20px;
    }
}

/* Media query for screens wider than 1000px */
@media (min-width: 1000px) {
    .featured-content {
        width: 63%;
        max-width: 63%;
    }

    .Newsletter {
        padding-right: 10px; /* Add this line */
    }
}

.featured-grid {
    display: grid;
    grid-template-columns: 67% 33%;
    gap: 10px;
}

.featured-image-container {
    width: 100%;
}

.featured-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.newsletter-container {
    padding: 10px;
    box-sizing: border-box; /* Add this line */
}

.search-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.large-search-input {
    width: 60%;
    font-size: 1.5em;
    padding: 10px;
    background-color: #f4f4f5; /* Zinc-100 color */
    border: 2px solid #4f46e5; /* Indigo-600 color */
    border-radius: 5px 0 0 5px;
    color: #3f3f46; /* Zinc-700 color for text */
}

.large-search-input::placeholder {
    color: #71717a; /* Zinc-500 color for placeholder text */
}

.large-search-button {
    font-size: 1.5em;
    padding: 10px 20px;
    background-color: #4f46e5; /* Indigo-600 color */
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.large-search-button:hover {
    background-color: #4338ca; /* Indigo-700 color for hover state */
}

/* Update the border color for elements with indigo borders */
.search-container,
.featured-wrapper,
.recipe-item,
/* Add any other relevant selectors here */
{
    border-color: #8E8E93; /* Space Grey color */
}
