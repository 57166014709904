.newsletter {
    width: 33%; /* 33% of the width of the featured image */
    margin-left: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.input-group {
    margin: 10px 0;
}

.input-group label {
    display: block;
    font-weight: bold;
}

.input-group input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
}

.submit-button {
    background-color: #4338ca; /* Updated to match the search button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.featured-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.featured-image {
    width: 60%; /* Ensure the featured image takes 60% width */
    height: auto;
}
