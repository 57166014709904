.shadow-sm {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.bg-white {
    background-color: #ffffff;
}

.text-black {
    color: #000000;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.p-4 {
    padding: 1rem;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.gap-4 {
    gap: 1rem;
}

.header-logo {
    max-height: 80px;
    width: auto;
    display: block;
    border: 0;
    outline: none;
}

.border {
    border: 1px solid #ccc;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.rounded {
    border-radius: 0.25rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    display: inline;
}

ul li a {
    text-decoration: none;
    color: #000000; /* Text color for links */
}

ul li a:hover {
    color: #12b488;
}

input[type="search"] {
    padding: 5px;
    font-size: 16px;
    width: 60%;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
